/** @jsx jsx */
/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/core'
import {injectIntl} from 'react-intl';
import { StaticQuery, graphql } from 'gatsby'
import {RichText} from 'prismic-reactjs';
import striptags from 'striptags'
import { renderToString } from 'react-dom/server'
import PetsSignUpBlock from '../../blocks/PetsSignUpBlock'

import { Flex, Box } from '../../common/Grid'

import DidYouKnow from '../../common/DidYouKnow'

import Header from '../../theme/Header'
import {
 // TextBlock,
  BulletListBlock,
  NumberedListBlock,
  VideoBlock,
  TipBlock,
  CouponBlock
} from '../../blocks'

const BlogHeaderWrapper = styled.div`
  ${tw`bg-light-blue`}
  padding: 86px 0px 183px;
  overflow: hidden;

  h1 {
    ${tw`font-montserrat font-normal text-4xl text-black mt-2 mb-5`}
    line-height: 46px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 830px;
  margin: 0 auto;

  @media screen and (max-width: 64em) {
    ${tw`px-5`}
  }
`

const Category = styled.h3`
  ${tw`font-raleway italic font-normal text-xl text-blog-text mt-0 tracking-tight`}
  line-height: 24px;
`

const TextBlockWrapper = styled.div`
  ${tw`font-raleway text-lg text-blog-text leading-loose mb-8`}

  p {
    ${tw`m-0 mb-2`}

    span.label {
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
      position: relative;
      top: -.3em;
    }
  }

  h2 {
    ${tw`font-montserrat font-medium text-4xl text-blog-text leading-tight mb-3`}
  }

  h3 {
    ${tw`font-montserrat font-light text-3xl text-blog-text leading-loose mb-3`}
  }

  h4 {
    ${tw`font-montserrat font-light text-1xl text-blog-text leading-loose mb-3`}
  }

  h5 {
    ${tw`font-montserrat font-light text-large text-blog-text leading-loose mb-3`}
  }

  h6 {
    ${tw`font-montserrat font-light text-base text-blog-text leading-loose mb-3`}
  }

  a {
    ${tw`text-border-blue no-underline focus:underline hover:underline`}
  }
`

const SideBySideWrapper = styled.div`
  ${tw`mb-3`}

  @media screen and (max-width: 40em) {
    margin: 0px -20px;
  }
`

const Spacer = styled.div`
  ${tw`my-3`}
`

class BlogPage extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            locale:props.intl.locale
        }
    }

    render(){
        const { data, intl } = this.props
        const postData = data
        return (
          <React.Fragment>
            <Header
              locale={intl.locale}
              urls={{
                'en': (intl.locale === 'en') ? `/home-solutions/${data._meta.uid}` : `/home-solutions/${data._meta.alternateLanguages[0].uid}`,
                'fr': (intl.locale === 'fr') ? `/fr/solutions-maison/${data._meta.uid}` : `/fr/solutions-maison/${data._meta.alternateLanguages[0].uid}`
              }}
            />

            <BlogHeaderWrapper>
              <Container>
                <Category>{data.category.name[0].text}</Category>
                <RichText render={data.title} />
              </Container>
            </BlogHeaderWrapper>
            <div css={css`margin-top:-160px;overflow: hidden;`}>
              {data.body.map((block) => {
                let ele = null

                // eslint-disable-next-line
                switch (block.type) {
                  case 'text':
                    ele = (
                      <TextBlockWrapper>
                        <RichText render={block.primary.text} />
                      </TextBlockWrapper>
                    )
                    break;
                  case 'side_by_side_image':
                    ele = (
                      <SideBySideWrapper>
                        <Flex flexWrap="wrap" mx={[0, 0, 0, -2]}>
                          <Box width={[1, 1, 1, 1/2]} px={[0, 0, 0, 2]}>
                            {block.primary.left_image ? <img src={block.primary.left_image.url} alt="" /> : null }
                          </Box>
                          <Box width={[1, 1, 1, 1/2]} px={[0, 0, 0, 2]} mt={[3,3,3,0]}>
                            {block.primary.right_image ? <img src={block.primary.right_image.url} alt="" /> : null }
                          </Box>
                        </Flex>
                      </SideBySideWrapper>
                    )
                    break;
                  case 'bullet_list':
                    ele = (
                      <BulletListBlock
                        title={block.primary.title1[0].text}
                        list={block.fields}
                      />
                    )
                    break;
                  case 'number_list':
                    ele = (
                      <NumberedListBlock
                        title={block.primary.title1[0].text}
                        list={block.fields}
                      />
                    )
                    break;
                  case 'video':
                    ele = (
                      <VideoBlock url={block.primary.video.embed_url} />
                    )
                    break;
                  default:
                    /* console.log(`BLOCK ${block.slice_type} NOT DEFINED`); */
                }

                return <Container><Spacer>{ele}</Spacer></Container>
              })}
            </div>

            {
              (data.tip)
              ?
                (
                  <TipBlock
                    icon={data.tip.icon}
                    title={data.tip.title}
                    image={(data.tip.tip_image) ? data.tip.tip_image.url : null}
                    color={data.tip.tip_background}
                    style={data.tip.tip_style}
                    buttonUrl={data.tip.tip_link}
                    buttonName={data.tip.button_name[0].text}
                    body={data.tip.description}
                  />
                )
              :
                null
            }



            {
              (data.coupon)
                ?
                  (
                    <CouponBlock
                      subtitle={data.coupon.sub_title}
                      title={data.coupon.title}
                      buttonUrl={data.coupon.coupon_link}
                      buttonName={data.coupon.button_name[0].text}
                      eventName={data.coupon.coupon_event_name[0].text}
                      body={data.coupon.description}
                      image={data.coupon.image}
                    />
                  )
                :
                  null
            }

            <StaticQuery
              query={graphql`
                {
                prismic {
                  blog_1: home_solution_article(uid: "were-big-fans-of-this-energy-saving-tip", lang: "en-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                  blog_2: home_solution_article(uid: "a-natural-way-to-keep-your-furry-friends-ears-clean", lang: "en-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                  blog_3: home_solution_article(uid: "the-grill-mate-you-should-never-be-without", lang: "en-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                  blog_4: home_solution_article(uid: "corn-on-the-cob-youll-be-amaized-by", lang: "en-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                  blog_5: home_solution_article(uid: "seventer-proprement-tout-en-economisant", lang: "fr-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                  blog_6: home_solution_article(uid: "tenir-les-oreilles-de-nos-petites-boules-de-poils-bien-propres", lang: "fr-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                  blog_7: home_solution_article(uid: "laide-barbecue-a-toujours-avoir-sous-la-main", lang: "fr-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                  blog_8: home_solution_article(uid: "de-surprenants-epis-de-mais", lang: "fr-ca") {
                    title
                    preview_image
                    _meta {
                      id
                      uid
                    }
                  }
                }
              }
              `}
              render={data => {
                return (
                    <DidYouKnow blogPosts={data.prismic} locale={intl.locale} currentUid={postData._meta.uid} alterSyntax={true} />
                )
              }}
            />
          { postData.category.name[0].text == 'Pet Care' || postData.category.name[0].text == 'Soins des animaux' ?
              <PetsSignUpBlock locale={intl.locale} />
              :null
            }

          </React.Fragment>
        )
    }
}

export default injectIntl(BlogPage)
